import styled from 'styled-components';
import { BiLinkExternal } from 'react-icons/bi';
import { IoLogoStackoverflow } from 'react-icons/io5';
import { AiFillGithub, AiFillLinkedin } from 'react-icons/ai';

import { EThemes } from '../../Theme/theme';
import { useTheme } from '../../hooks/useTheme';
import ScreenLayout from '../../components/Layout/ScreenLayout';

const Me = () => {
	const { isDark } = useTheme();

	return (
		<ScreenLayout title='Sr. Software Engineer'>
			<Main>
				<Sides colSpan='span 8'>
					<Each>
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							<Title>Senior Software Engineer | Fullstack</Title>
							<ChipContainer style={{ gap: 20 }}>
								<a rel='noreferrer' target='_blank' href='https://stackoverflow.com/users/13089572/adiat-hasan'>
									<IoLogoStackoverflow color='orange' size={26} />
								</a>
								<a rel='noreferrer' target='_blank' href='https://www.linkedin.com/in/adiathasan'>
									<AiFillLinkedin size={26} />
								</a>
								<a rel='noreferrer' target='_blank' href='https://github.com/adiathasan'>
									<AiFillGithub color={isDark ? 'white' : '#484848'} size={26} />
								</a>
							</ChipContainer>
						</div>
						<Text>
							Highly motivated and experienced Fullstack Software Developer with a passion for frontend engineering.
							<br />
							<br />
							My expertise mainly lies within Typescript, React.js, Node.js & Nest.js to create clean, reusable, and
							maintainable code. But this doesn't stop me from learning new technologies and languages and adopting them
							when needed.
							<br />
							<br />
							Currently I'm focusing on open source projects as it leads to a better understanding of certain concepts
							and also helps me to further sharpen my skills.
						</Text>
					</Each>
					<Each>
						<Title>Work Experience</Title>

						<Subtitle>
							Senior Software Engineer{' '}
							<a target='_blank' rel='noreferrer' href='https://bluetechbd.com'>
								Blue Tech
							</a>{' '}
							(October 2021 - present)
						</Subtitle>
						<Text>
							1. Architected the frontend for bdtickets.com and a few internal apps. <br />
							2. Implemented scalable design patterns in client and server side <br />
							3. Reactive development for the type-safe data layer with React Query. <br />
							4. Adapted advanced patterns for effortless integration of api's with autogenerated type-safe hooks.{' '}
							<br />
						</Text>
						<ChipContainer>
							<Chip>Typescript</Chip>
							<Chip>React</Chip>
							<Chip>React-native</Chip>
							<Chip>Next</Chip>
							<Chip>Node</Chip>
							<Chip>Nest</Chip>
							<Chip>SSR</Chip>
							<Chip>SSG</Chip>
							<Chip>React-query</Chip>
							<Chip>Antd</Chip>
						</ChipContainer>
						<Subtitle>
							Frontend Engineer{' '}
							<a target='_blank' rel='noreferrer' href='https://xen.works'>
								Xen Works
							</a>{' '}
							(August 2021 - present)
						</Subtitle>
						<Text>
							1. Responsible for developing scalable UIs with solid principles. <br />
							2. Introducing effortlessly scalable type-safe integrations with material UI inputs and react-hook-form.{' '}
							<br />
							3. Adapting React Query while replacing redux for server-side type-safe data management through apis.{' '}
							<br />
							4. Introducing the pattern for autogenerated custom hooks for type-safe data fetching and mutation. <br />
							5. Providing solutions to team members for better optimization and maintainability of code. <br />
							6. Decision-making to further improvement of code quality and performance.
							<br />
						</Text>
						<ChipContainer>
							<Chip>Typescript</Chip>
							<Chip>React</Chip>
							<Chip>Redux</Chip>
							<Chip>Redux-logics</Chip>
							<Chip>React-query</Chip>
							<Chip>Tailwind</Chip>
							<Chip>Styled-components</Chip>
						</ChipContainer>
						<Subtitle>
							Frontend Engineer{' '}
							<a target='_blank' rel='noreferrer' href='https://now.com.bd'>
								Now
							</a>{' '}
							(January 2020 - July 2021)
						</Subtitle>
						<Text>
							1. Was in charge of developing the client side of now.com.bd from start to finish. <br />
							2. Technology used: React, Next.js, Typescript, React Query, Custom Hooks, Graphql, Sass, Tailwind. <br />
							3. Made the technical decisions by consulting with our tech lead to improve the projects.
							<br />
							4. Worked on Rider tracking mobile app [React Native]
							<br />
						</Text>
						<ChipContainer>
							<Chip>Typescript</Chip>
							<Chip>React</Chip>
							<Chip>React-native</Chip>
							<Chip>Next</Chip>
							<Chip>SSG</Chip>
							<Chip>React-query</Chip>
							<Chip>Graphql</Chip>
							<Chip>Parse Server</Chip>
							<Chip>Tailwind</Chip>
							<Chip>Styled-components</Chip>
						</ChipContainer>
					</Each>
					<Each>
						<Title>Open Source Contributions</Title>
						<Title>
							<a target='_blank' rel='noreferrer' href='https://github.com/adiathasan/mui-react-hook-form-plus'>
								Mui React Hook Form&nbsp;+
								<ExternalIcon style={{ marginLeft: 4, marginBottom: 1 }} />
							</a>
							<img
								alt='stars'
								src='https://badgen.net/github/stars/adiathasan/mui-react-hook-form-plus'
								style={{ transform: 'translateY(5px) translateX(16px)', width: 60, float: 'right' }}
							/>
						</Title>
						<Text>
							The perfect recipe with <Chip>material-ui</Chip> 💙 <Chip>TS</Chip> 💙 <Chip>react-hook-form</Chip> &
							more. The complete type-safe material-ui and react-hook-form combo and beyond with simple api. Highly
							Customizable and supports 99% use-cases.
							<br />
							<br />
							<Chip>Material ui</Chip> is effective for building UI's of great UX & DX in a <Chip>React</Chip> project.
							To make it stateful, we have pay the price of sacrificing DX & sometimes lack of performance and
							no-scalability.{' '}
						</Text>
						<br />
						<br />
						<Title>
							<a target='_blank' rel='noreferrer' href='https://github.com/adiathasan/react-store-maker'>
								React Store Maker <ExternalIcon style={{ marginLeft: 4 }} />
							</a>
							<img
								alt='stars'
								src='https://badgen.net/github/stars/adiathasan/react-store-maker'
								style={{ transform: 'translateY(5px) translateX(16px)', width: 60, float: 'right' }}
							/>
						</Title>
						<Text>
							It is a utility function for creating stores for global / local state management with the{' '}
							<Chip>Context API</Chip> approach in React.js applications. This simplifies contexts and reduces boiler
							plate code and has some built in optimization.
							<br />
							<br />
							While building the architecture BD tickets app I didn't want to use redux or other heavy state management
							library. I was using Contexts for small things and react-query for data fetching. Then I felt the
							necessity of writing a function that would just give the context instead of writing a lot of boiler plate
							code.{' '}
						</Text>
						<br />
						<br />
						<Title>
							<a target='_blank' rel='noreferrer' href='https://github.com/adiathasan/react-step-machine'>
								React Step Machine <ExternalIcon />
							</a>
							<img
								alt='stars'
								src='https://badgen.net/github/stars/adiathasan/react-step-machine'
								style={{ transform: 'translateY(5px) translateX(16px)', width: 60, float: 'right' }}
							/>
						</Title>
						<Text>
							A hook-based multi-step wizard library made for React.js apps with vast control over the logic of the user
							as per use-case. It's API is much simpler than other step wizards out there.
							<br />
							<br />
							The inspiration came form{' '}
							<a target='_blank' rel='noreferrer' href='https://github.com/jcmcneal/react-step-wizard'>
								react-step-wizard
							</a>
							. It didn't support my use-cases that I needed for my project. Hence, I built my own step wizard with
							recent react concepts.{' '}
						</Text>

						<br />
						<br />
						<Title>
							<a target='_blank' rel='noreferrer' href='https://adiathasan.vercel.app'>
								React Concepts <ExternalIcon style={{ marginLeft: 4, marginBottom: 0 }} />
							</a>
							<img
								alt='stars'
								src='https://badgen.net/github/stars/adiathasan/react-concepts'
								style={{ transform: 'translateY(5px) translateX(16px)', width: 60, float: 'right' }}
							/>
						</Title>
						<Text>
							Advanced concepts of react such as hooks, contexts, reusable components, custom hooks, prop getters, theme
							with styled-components, animations and more.
							<br />
							<br />
							When I learn a new concept in react I tend to practice it and share my work so that other developers can
							grasp the concepts and remain upto date with the industry standard.{' '}
						</Text>
					</Each>
				</Sides>
				<Sides colSpan='span 4'>
					<Each>
						<Subtitle>General Information's</Subtitle>
						<Text>London 🇬🇧 United Kingdom</Text>
						<Text style={{ margin: '12px 0' }}>
							<a href='tel:+447432113152'>+44 7432 113152</a>
						</Text>
						<Text style={{ margin: '12px 0' }}>
							<a href='mailto:adiathasan.me@gmail.com'>adiathasan.me@gmail.com</a>
						</Text>
					</Each>
					<Each>
						<Subtitle>Skills</Subtitle>
						<ChipContainer>
							<Chip>Javascript</Chip>
							<Chip>Typescript</Chip>
							<Chip>Css</Chip>
							<Chip>Node</Chip>
							<Chip>Express</Chip>
							<Chip>Nest</Chip>
							<Chip>Parse Server</Chip>
							<Chip>Monorepo</Chip>
							<Chip>React</Chip>
							<Chip>React-native</Chip>
							<Chip>Next</Chip>
							<Chip>Svelte</Chip>
							<Chip>Solid.js</Chip>
							<Chip>React-query</Chip>
							<Chip>React-hook-form</Chip>
							<Chip>Material-ui</Chip>
							<Chip>React-testing-library</Chip>
							<Chip>Jest</Chip>
							<Chip>Redux</Chip>
							<Chip>Graphql</Chip>
							<Chip>Tailwind</Chip>
							<Chip>Antd</Chip>
							<Chip>Styled-components</Chip>
						</ChipContainer>
					</Each>
					<Each>
						<Subtitle>Related Links</Subtitle>
						<Text style={{ margin: '12px 0' }}>
							<a href='https://github.com/adiathasan'>github.com/adiathasan</a>
						</Text>
						<Text style={{ margin: '12px 0' }}>
							<a href='https://linkedin.com/in/adiathasan'>linkedin.com/adiathasan</a>
						</Text>
						<Text style={{ margin: '12px 0' }}>
							<a href='https://leetcode.com/adiathasan'>leetcode.com/adiathasan</a>
						</Text>
						<Text style={{ margin: '12px 0' }}>
							<a href='https://stackoverflow.com/users/13089572/adiat-hasan'>stackoverflow.com/adiat-hasan</a>
						</Text>
						<Text style={{ margin: '12px 0' }}>
							<a href='https://adiathasan.medium.com'>adiathasan.medium.com</a>
						</Text>
					</Each>
					<Each>
						<Subtitle>Blogs</Subtitle>
						1.{' '}
						<a href='https://adiathasan.medium.com/mui-react-hook-form-plus-material-ui-react-hook-form-made-simple-6c6778d9a0e7'>
							React Form made simple 🚀
						</a>
						<br />
						<br />
						2.{' '}
						<a href='https://adiathasan.medium.com/ditching-redux-in-react-part-1-e29dc7fe64c7'>
							Ditching Redux in React — Part 1 🏎️
						</a>
						<br />
						<br />
						3.{' '}
						<a href='https://medium.com/@adiathasan/typesafe-routes-in-next-js-a5f3f4d3cbbb'>
							Typesafe routes in Next.js 📘
						</a>
					</Each>
					<Each>
						<Subtitle>Education</Subtitle>
						BSc in Computer Science & Engineering
						<br />
						<br />
						University of The People (2020 - present)
					</Each>
					<Each>
						<Subtitle>Certifications</Subtitle>
						<br />
						<Subtitle>
							Epic React by kent C. Dodds | Advanced React Patterns (01/2022 - Present){' '}
							<a
								target='_blank'
								rel='noreferrer'
								href='https://drive.google.com/file/d/1tnMpk5WdIyACLeFoCYA6c9NyQ2bZLpQm/view?usp=sharing'>
								<ExternalIcon style={{ transform: 'translateY(3px)' }} />
							</a>
						</Subtitle>
						<br />
						<Subtitle>
							HTML, CSS, and Javascript for Web Developers(coursera) (08/2020 - 08/2020){' '}
							<a
								target='_blank'
								rel='noreferrer'
								href='https://www.coursera.org/account/accomplishments/verify/V3VKL9396UYL?utm_source=link&utm_medium=certificate&utm_content=cert_image&utm_campaign=sharing_cta&utm_product=course'>
								<ExternalIcon style={{ transform: 'translateY(3px)' }} />
							</a>
						</Subtitle>
						<br />
						<Subtitle>
							Machine-learning(coursera) (05/2020 - 06/2020){' '}
							<a
								target='_blank'
								rel='noreferrer'
								href='https://www.coursera.org/account/accomplishments/verify/CT7DHVZFXGEM?utm_source=link&utm_medium=certificate&utm_content=cert_image&utm_campaign=sharing_cta&utm_product=course'>
								<ExternalIcon style={{ transform: 'translateY(3px)' }} />
							</a>
						</Subtitle>
					</Each>
					<Each hide />
					<Each hide />
					<Each hide />
					<Each hide />
					<Each hide />
					<Each hide />
					<Each hide />
					<Each hide />
				</Sides>
			</Main>
		</ScreenLayout>
	);
};

export default Me;

export const Main = styled.main`
	width: 1400px;
	max-width: 100%;
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-gap: 1.4rem;
	min-height: 90vh;
	margin-top: 20px;
	margin-bottom: 60px;

	@media (max-width: 780px) {
		grid-template-columns: 1fr;
	}
`;

export const Each = styled.section<{ colSpan?: string; rowSpan?: string; hide?: boolean }>`
	background-color: ${({ theme }) => theme[EThemes.BG_SECONDARY]};
	color: ${({ theme }) => theme[EThemes.TEXT_PRIMARY]};
	padding: 1rem 1.5rem;
	grid-column: ${({ colSpan }) => colSpan};
	grid-row: ${({ rowSpan }) => rowSpan};
	opacity: ${({ hide }) => (hide ? 0 : 1)};

	@media (max-width: 780px) {
		grid-column: span 12;
	}
`;

export const Sides = styled.section<{ colSpan?: string; rowSpan?: string }>`
	grid-column: ${({ colSpan }) => colSpan};
	grid-row: ${({ rowSpan }) => rowSpan};
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 1.4rem;

	@media (max-width: 780px) {
		grid-column: span 12;
		grid-gap: 1rem;
	}
`;

export const Title = styled.h2`
	font-weight: bold;
	margin-bottom: 20px;

	@media (max-width: 780px) {
		font-size: 1.2rem;
	}
`;

export const ExternalIcon = styled(BiLinkExternal)`
	transform: translateY(5px);
`;

export const Chip = styled.span`
	border-radius: 4px;
	padding: 0.3rem 0.5rem;
	background-color: ${({ theme }) => theme[EThemes.BG_PRIMARY]};
	font-size: smaller;
`;

export const ChipContainer = styled.div`
	margin-top: 10px;
	margin-bottom: 30px;
	display: flex;
	gap: 6px;
	flex-wrap: wrap;
`;

export const Subtitle = styled.h4`
	font-weight: 600;
	margin-bottom: 14px;
	letter-spacing: 0.03rem;
	line-height: 25px;

	@media (max-width: 780px) {
		font-size: 0.9rem;
	}
`;

export const Text = styled.p`
	font-weight: 380;
	line-height: 25px;
	letter-spacing: 0.03rem;
	margin-bottom: 0.2rem;

	@media (max-width: 780px) {
		font-size: 0.9rem;
	}
`;
